.initial-grid-margin-top {
	margin-top: 2rem !important;
}

.grid-margin-top {
	margin-top: 1rem !important;
}

.float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}

.my-float {
	margin-top: 16px;
}

.distanceFromTop {
	top: 10%
}

@media (min-width: 1600px) {
	.distanceFromTop {
		top: 18%
	}
}