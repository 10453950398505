@font-face {
    font-family: 'FS Emeric';
    src: url('FSEmeric-Bold.woff2') format('woff2'),
        url('FSEmeric-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.fs-emeric {
    font-family: "'FS Emeric'";
    font-weight: bold;
    font-style: normal;
}